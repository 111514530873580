import React from 'react';


export const NotImplementedAddonToggleButton: React.FunctionComponent = () => {
    return (
        <React.Fragment>
            <div>Toggle button not implemented</div>
        </React.Fragment>
    );
};
