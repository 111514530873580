import React, {useEffect, useState} from 'react';
import {
    Button,
    HelperText,
    HelperTextItem,
    Modal,
    ModalVariant,
    Text,
    TextContent,
    TextVariants,
    Tooltip
} from '@patternfly/react-core';
import {useFilesStore, useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {ProjectAddOnService} from "../../api/ProjectAddOnService";
import {ProjectAddOnType} from "../../api/SmhProjectModels";
import {EventBus} from "../../designer/utils/EventBus";


export const CustomExceptionLoggerAddonToggleButton: React.FunctionComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [project] = useProjectStore((state) => [state.project], shallow )
    const [files] = useFilesStore((s) => [s.files], shallow);
    const [isAddonEnabled, setIsAddonEnabled] = useState(false);

    const addonFileNames = ['CustomExceptionLogger.java', 'custom_exception_logger.conf']

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const calculateIsAddonEnabled = () => {
        return files.filter(f => addonFileNames.includes(f.name)).length === 2;
    }

    const getModalTitle = () => {
        return isAddonEnabled ? "Warning" : "Info";
    }

    const getModalTitleIconVariant = () => {
        return isAddonEnabled ? "danger" : "info";
    }

    useEffect(() => {
        setIsAddonEnabled(calculateIsAddonEnabled())
    }, []);

    const handleAddOnToggleConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        if(!isAddonEnabled) {
            ProjectAddOnService.enableAddOn(project.projectId, ProjectAddOnType.CUSTOM_EXCEPTION_LOGGER);
            EventBus.sendAlert( "Success", "Custom Exception Logger addon enabled", "success");

            setIsAddonEnabled(true);
        } else {
            ProjectAddOnService.disableAddOn(project.projectId, ProjectAddOnType.CUSTOM_EXCEPTION_LOGGER);
            EventBus.sendAlert( "Success", "Custom Exception Logger addon disabled", "success");

            setIsAddonEnabled(false);
        }

        setIsModalOpen(!isModalOpen);
    };

    return (
        <React.Fragment>
            {isAddonEnabled &&
                <Tooltip content={"Disable Custom Exception Logger Add-On"} position={"bottom"}>
                    <Button variant="danger" onClick={handleModalToggle} >
                        Disable
                    </Button>
                </Tooltip>
            }
            {!isAddonEnabled &&
                <Tooltip content={"Enable Custom Exception Logger Add-On"} position={"bottom"}>
                    <Button variant="primary" onClick={handleModalToggle}>
                        Enable
                    </Button>
                </Tooltip>
            }
            <Modal
                variant={ModalVariant.medium}
                title={getModalTitle()}
                titleIconVariant={getModalTitleIconVariant()}
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleAddOnToggleConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                {!isAddonEnabled &&
                    <TextContent>
                        <Text component={TextVariants.h3}>Are you sure you want to enable Custom Exception Logger Add-On?</Text>
                    </TextContent>
                }
                {isAddonEnabled &&
                    <TextContent>
                        <Text component={TextVariants.h3}>Are you sure you want to disable Custom Exception Logger Add-On?</Text>
                        <HelperText>
                            <HelperTextItem variant="warning">
                                Integration will fail to build and stop functioning if it's setup to use Custom Exception Logger addon.
                            </HelperTextItem>
                        </HelperText>
                    </TextContent>
                }
            </Modal>
        </React.Fragment>
    );
};
