import React from 'react';
import {PageSection, Panel, PanelHeader, PanelMain, PanelMainBody} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";
import {ProjectAddOns} from "../../api/SmhProjectModels";

export const ProjectAddOnsTab = () => {
    const addons = ProjectAddOns;

    let lineNumber = 1;

    return (
        <PageSection className="project-tab-panel" padding={{default: "padding"}}>
            <Panel isScrollable>
                <PanelHeader style={{paddingBottom:'10px'}}>
                </PanelHeader>
                <PanelMain maxHeight={'calc(100vh - 300px)'}>
                    <PanelMainBody>
                        <Table aria-label="Project Add-Ons" variant={"compact"} className={"table"}>
                            <Thead>
                                <Tr>
                                    <Th key='name'>Name</Th>
                                    <Th key='description'>Description</Th>
                                    <Th key='actions'>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {addons.map(line => {
                                    lineNumber = lineNumber + 1;
                                    let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                    return <Tr key={line.name} style={{backgroundColor: backgroundColor}}>
                                        <Td>{line.name}</Td>
                                        <Td>{line.description}</Td>
                                        <Td>{line.toggleButton && <line.toggleButton />}</Td>
                                    </Tr>
                                })}
                            </Tbody>
                        </Table>
                    </PanelMainBody>
                </PanelMain>
            </Panel>
        </PageSection>
    )
}
